<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="体温"></ls-title>

      <!-- 条件框 -->
      <div class="conddiv">
        <el-date-picker v-model="dateTime" style="margin-left:5px;" type="daterange" range-separator="至" format="YYYY 年 MM 月 DD 日"
                value-format="YYYY-MM-DD" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-select style="margin-left:5px;" v-model="state" placeholder="结果分析" size="large" clearable>
          <el-option v-for="item in stateOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
        <!-- <el-button v-if="this.role != 1" type="primary" style="float:right;" @click="add">曲线模式</el-button> -->
      </div>

      <!-- 表格 -->
      <el-table :data="dataList" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
        <el-table-column prop="createTime" label="日期"  label-class-name="tablehead" align="center" />
        <el-table-column prop="result" label="测量结果" label-class-name="tablehead" align="center">
          <template #default="scope">
            <span>体温:{{scope.row.etg}}°C</span>
          </template>
        </el-table-column>
        <el-table-column prop="source" label="数据来源" label-class-name="tablehead" align="center" />
        <el-table-column prop="stateDescribe" width="100" label="结果分析" label-class-name="tablehead" align="center" >
          <template #default="scope">
            <span v-if="scope.row.state == 0" style="color:green">{{scope.row.stateDescribe}}</span>
            <span v-if="scope.row.state == 1" style="color:red">{{scope.row.stateDescribe}}</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
      </div>
    </div>


</template>

<script>
import {getEtgInfos} from '../../../../api/api'
import session from "../../../../store/store";
import until from '../../../../until/until'

export default {
  data() {
    return {
      emptyText:'加载中',
      mainPage:true,
      dateTime: '',//条件
      startTime:'',
      endTime:'',
      state:'',//设备状态
      stateOption:[
        {value: 0,label: '全部'},
        {value: 1,label: '正常'},
        {value: 2,label: '异常'}
      ],
      currentPage: 1,
      size: 10,
      total: 0,//总数
      dataList: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getEtgInfos();
    },
    search() {
      if (!until.isNULL(this.dateTime)) {
        this.startTime = this.dateTime[0],
        this.endTime = this.dateTime[1]
      }else {
        this.startTime = '',
        this.endTime = ''
      }
      this.getEtgInfos();
    },
    getEtgInfos() {
      let param = {
        idcard:session.getCustomerData().idcard,
        page:this.currentPage - 1,
        size:this.size,
        startTime:this.startTime,
        endTime:this.endTime
      }
      if (!until.isNULL(this.state)) {
        param.stateSwitch = this.state
      }else {
        param.stateSwitch = 0
      }
      getEtgInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.dataList = res.data.dataInfo;
        }else {
          this.total = 0;
          this.dataList.splice(0, this.dataList.length);
          this.emptyText = "暂无数据"
        }
      })
    },

      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getEtgInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getEtgInfos();
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.textbtn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
.tablehead {
  color: white;
}
</style>